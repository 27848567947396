import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import NewPassword from "./NewPassword";
import { useNavigate } from "react-router-dom";
function App() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "") {
      navigate("/change/password/user");
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/change/password/user" element={<NewPassword />} />
      </Routes>
    </>
  );
}

export default App;
