import React, { useState } from 'react';
import axios from 'axios';
import { GetPublicToken } from './GenerateToken';
import './app.css';
import Logo from './logo.svg';
import { useNavigate } from 'react-router-dom';

const NewPassword = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageFail, setMessageFail] = useState('');
  const [show, setShow] = useState(false);

 // const { createProxyMiddleware } = require('http-proxy-middleware'); //cors

  const url = `${process.env.REACT_APP_URL_LINK}`;

  const queryParams = new URLSearchParams(window.location.search);
  const queryEmail = queryParams.get('email');

  const handlePassword = async () => {
    if (password !== repassword) {
      setShow(true);
      setMessageFail("Password doesn't match");
      setInterval(() => {
        setShow(false);
      }, 5000);
    } else {
      try {
        const config = {
          headers: {
             Authorization: GetPublicToken().Authorization,
            'Authorization-ID': GetPublicToken()['Authorization-ID'],
          },
          method: 'POST',
          url: `${process.env.REACT_APP_API_ACCOUNT}change/password/user?${queryParams}`,
          data: {
            password: password,
            repassword: repassword,
          },
        };
        console.log(config);

        const response = await axios(config);
        console.log(response);
        setShow(true);
        setMessage(response.data.message.en);

        setInterval(() => {
          setShow(false);
        }, 5000);

        setInterval(() => {
          window.location.href = url;
        }, 1000);
      } catch (error) {
        console.log(error);

        setShow(true);
        setMessageFail(error.response.data.message.en);

        setInterval(() => {
          setShow(false);
        }, 5000);
      }
    }
  };

  if (queryEmail === null || queryEmail === undefined || queryEmail === '') {
    return (
      <div className="mainDiv">
        <h3
          style={{
            textAlign: 'center',
            textWeight: 'bold',
          }}
        >
          Halaman Tidak Ditemukan, pastikan link sudah benar
        </h3>
      </div>
    );
  } else {
    return (
      <div className="mainDiv">
        <div className="cardStyle">
          <img src={Logo} id="signupLogo" />

          <h2 className="formTitle">Create your password</h2>

          <div className="inputDiv">
            <label className="inputLabel" htmlFor="password">
              New Password
            </label>
            <input
              type="password"
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>

          <div className="inputDiv">
            <label className="inputLabel" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              onChange={(e) => {
                setRepassword(e.target.value);
              }}
            />
          </div>

          {show ? (
            message ? (
              <div>
                <p
                  style={{
                    color: 'green',
                    textAlign: 'center',
                  }}
                >
                  {message}
                </p>
              </div>
            ) : (
              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                <p>{messageFail}</p>
              </div>
            )
          ) : null}

          <div className="buttonWrapper">
            <button onClick={handlePassword} className="submitButton pure-button pure-button-primary">
              <span className="buttonText">Submit</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default NewPassword;
